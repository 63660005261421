import { Grid, Image } from "@chakra-ui/react";
import imgHelp from "../assets/help.svg";

export default function GameBoardHelp() {
  return (
    <div>
      <Grid width="100%" justifyContent="center">
        <Image p="5px" src={imgHelp}></Image>
      </Grid>
    </div>
  );
}
