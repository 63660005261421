import React, { useState } from 'react';
import { Grid, Image, Box, FormControl, FormLabel, Input, Button } from "@chakra-ui/react";
import imgTrophy from '../assets/trophy.svg'
import gameState from "../utils/state";
import { useList } from "../liveblocks.config";

export default function GameWinner() {
  const [winnername, setWinnerName] = useState('');
  const [namesubmitted, setNameSubmitted] = useState(false);
  var winners:any = useList("winners");
  var today:Date = new Date();

  const handleSubmit = (event: { preventDefault: () => void; }) => {   
    event.preventDefault();
    gameState.winnerName = winnername;
    if (winners != null){
      winners.push({ 
        name: winnername,
        date: today.toDateString(),
        notified: false });
    }
    setNameSubmitted(true);
  };
 
  return (
    <div>
      <Grid width="100%" justifyContent="center">
      <Grid 
        mt="-400px"
        display={namesubmitted ? "none" : ""}
        p="50px" 
        width="400px" 
        justifyContent="center" 
        backgroundColor="white" 
        borderRadius="8px">
        <Box textAlign="center">You Win!</Box>
        <Grid justifyContent="center" width="100%">
          <Image p="5px"src={imgTrophy}></Image>
        </Grid>
        <form onSubmit={handleSubmit}>
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input 
              onChange={event => setWinnerName(event.currentTarget.value)}
              placeholder='Please enter your name' />
          </FormControl>       
          <Button 
            type="submit"
            backgroundColor="white"
            borderColor="black"
            border="2px"
            borderRadius="8px"         
            mt="25px"   
            alignSelf="center" 
            boxShadow="3px 2px 4px #e39234"           
           >Confirm</Button>    
        </form>  
      </Grid>  
      <Grid 
        mt="-400px"
        display={namesubmitted ? "" : "none"}
        p="50px" 
        width="400px" 
        justifyContent="center" 
        backgroundColor="white" 
        borderRadius="8px">
       
        <Box textAlign="center" fontSize="24px">Congratulations!</Box>
        <Box textAlign="center" fontSize="24px" borderBottom="1px" borderBottomColor="black">{winnername}</Box>
        <Grid justifyContent="center" width="100%">
          <Image 
            mt="25px" 
            height="163px" 
            p="5px"
            src={imgTrophy}>
          </Image>
        </Grid>      
      </Grid>  
      </Grid>   
    </div>
  );
}


