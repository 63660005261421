import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import { createRoot } from "react-dom/client";
import AppRouter from "./AppRouter";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";
import { RoomProvider } from "./liveblocks.config";
import { LiveList } from "@liveblocks/client";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <RoomProvider
      id="react-pickcel-app"
      initialStorage={{
        winners: new LiveList(),  
        user: new LiveList(),      
      }}
    >
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <AppRouter />
    </RoomProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
