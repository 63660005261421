import PageHeader from "../components/PageHeader";
import GameWinner from "../components/GameWinner";
import PageFooter from "../components/PageFooter";
import {
  ChakraProvider,
  Box,
  Grid,
  theme,
  useColorModeValue,
} from "@chakra-ui/react";

/**
 * Test Screen
 *
 * using this page to test components
 *
 *
 * @returns the test page
 */
export function TestScreen() {
  // set the colour theme for the page
  const bg = useColorModeValue("#ffe248", "#ffb800");
  const color = useColorModeValue("black", "white");

  return (
    <ChakraProvider theme={theme}>
      <Box
        textAlign="left"
        fontSize="20px"
        bg={bg}
        color={color}
        fontFamily="Jost"
        fontWeight="600"
      >
        <Grid minH="100vh" minW="100vw" p={5}>
          <PageHeader userMsg="" />
          <GameWinner />
          <PageFooter />
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default TestScreen;
