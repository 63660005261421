import { useOthers, useList} from "../liveblocks.config";
import { Box  } from "@chakra-ui/react";
import { useToast } from '@chakra-ui/react'

  function WhoIsHere() {
    const others = useOthers(); // other players currently online, from live blocks
    var winners:any = useList("winners"); // list of previous winners, from live blocks
    const toast = useToast();
    if (winners === null){
      return(
        <Box>Live Blocks Loading....</Box>
      )
    }

    /* 
      all winners for each day are stored on live blocks
      the most recent winner is at the end of the list 
    */
   if (winners.length <= 0){
      winners.push({ 
        name: "No winners yet",
        date: "",
        notified: true });
    }
    var index:number = winners.length -1; // the last winner is the last entry in the live list
    var winner:any = winners.get(index); 
      
    // check if the other players have been notified of their win
    if (!winner.notified){
      // notify the other players that we have a winner
      winner.notified = true;
      winners.set(index, winner);
      toast({
        title: 'Game Over',
        description: `${winner.name} found the Pickcel for ${winner.date}!`,
        status: 'info',
        duration: 9000,
        isClosable: true,
        position: 'top',
      })
    }    
    
    return (
      <Box fontSize="14px" textAlign="right">
        <Box>Last Winner: { winner.name } {winner.date}</Box>
        <Box>{others.count} other players online</Box>
      </Box>
    );
}

export default WhoIsHere;