export const COLUMNS = 8;
export const ROWS = 8;
export const CELL_SIZE = 40;
export const CELL_STATUS_FAR = 3;
export const CELL_STATUS_CLOSE = 2;
export const CELL_STATUS_TREASURE = 1;

export interface IGrid {
  [key: string]: number[];
}

export const getCells = (num: number): IGrid => {
  const arr = new Array(num).fill(CELL_STATUS_FAR);

  let ob: any = { ...arr };
  Object.keys(ob).forEach((item) => {
    ob[item] = [...arr];
  });

  return ob;
};

export type PickcelProp = {
  columns: number;
  cell_type_treasure: number;
  cell_type_close: number;
  cell_size: number;
};
