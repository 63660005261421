import React from "react";
import PageHeader from "../components/PageHeader";
import GameBoard from "../components/GameBoard";
import PageFooter from "../components/PageFooter";
import { COLUMNS, CELL_STATUS_TREASURE, CELL_STATUS_CLOSE, CELL_SIZE } from "../utils/GameBoardUtils";
import {
  ChakraProvider,
  Box,
  Grid,
  theme,
  useColorModeValue 
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

/** URL Game Parameters
 *  indicates if working from home or office 
 */
type GameParams = {
  working : string;
}
/**
 * Game Board Screen
 * 
 * @returns the page that holds the game board
 */
export function GameBoardScreen() { 
  // set the colour theme for the page
  const bg = useColorModeValue('#ffe248', '#ffb800');
  const color = useColorModeValue('black', 'white');

  const {working} = useParams<GameParams>(); /* at home or in the office? */

  let msg = "Working from "+working;
  if (working==="office"){
    msg = "So glad you made it into the office today";
  }
  if (working==="home"){
    msg = "Sorry you couldn't make it into the office today";
  }

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="left" fontSize="20px" bg={bg} color={color} fontFamily="Jost" fontWeight="600">
        <Grid minH="100vh" minW="100vw" p={5}>
          <PageHeader userMsg={msg} />
          <GameBoard 
            columns={COLUMNS}
            cell_type_treasure={CELL_STATUS_TREASURE}
            cell_type_close={CELL_STATUS_CLOSE}
            cell_size={CELL_SIZE}
          />
          <PageFooter />
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default GameBoardScreen;
