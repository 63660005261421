import React from "react";
import {
  Link,
  Box,
  Image,
  SimpleGrid,
  IconButton,
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import { FaEllipsisH } from "react-icons/fa";
import GameBoardHelp from "./GameBoardHelp";
import WhoIsHere from "./WhoIsHere";

/** 
 * Page Header Props 
 *  
 * Displays a message to the user
 */
type Props = {
  userMsg: string;
}

/**
 * AppHeader component
 * 
 * @returns the header for the GameBoard page
 */
function PageHeader({userMsg} : Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  function Help(){
    onOpen();
  }
 
  return (
    <Box textAlign="left">
      <SimpleGrid gridTemplateColumns="5fr 5fr">
        <Link href="/">
          <Image src='/pickcel-black.png' height="15px" mt="20px"></Image>  
        </Link>
        <WhoIsHere />       
      </SimpleGrid>      
      <Box height="2px" bg="black" m="5px 0 10px 0" />
      <SimpleGrid gridTemplateColumns="20fr 5fr">
      <Box fontSize="14px">
        {userMsg}
      </Box>
        <Box justifySelf="flex-end">
          <IconButton size="xs" 
                    aria-label="Help" 
                    isRound={true} 
                    icon={<FaEllipsisH />} 
                    cursor="pointer" 
                    bg="" 
                    onClick={Help} 
                    boxShadow="1px 1px 1px 1px gray"                   
          >          
          </IconButton>
          <Modal isOpen={isOpen} onClose={onClose} isCentered >
            <ModalContent height="380px" width="380px">
              <ModalCloseButton />
              <ModalBody>
                <GameBoardHelp />
              </ModalBody>                          
            </ModalContent>
          </Modal>      
        </Box>      
      </SimpleGrid>     
    </Box>   
  );
}

export default PageHeader;
