import PageHeaderPlay from '../components/PageHeaderPlay';
import PageFooter from '../components/PageFooter'
import imgHome from '../assets/at-home.svg' // work from home 
import imgOffice from '../assets/in-office.svg' // work from office 
import { useList} from "../liveblocks.config";
import {
  ChakraProvider,
  Box,
  Grid,
  SimpleGrid,
  Image,
  theme,
  useColorModeValue
} from "@chakra-ui/react";

import GeoLocation from "../components/GeoLocation";
import { useGeoLocation } from 'use-geo-location';
import { calcGeoDistance, checkWinnerToday, WORK_LATITUDE, WORK_LONGITUDE } from '../utils/utils';
import {useNavigate} from "react-router-dom";
import gameState from '../utils/state';

/**
 *  The Play Screen
 * 
 * @returns the play screen
 */
export function PlayScreen() { 
  const bg = useColorModeValue('#ffe248', '#ffb800');
  const color = useColorModeValue('black', 'white');
  const selectedColour = "#e39234";
  const { latitude, longitude } = useGeoLocation();

  // calculate the distance between the players location and the office
  let distance = calcGeoDistance({lat:latitude, lon:longitude}, {lat:WORK_LATITUDE, lon:WORK_LONGITUDE})
 
  // default settings while working out if the player is at home or in the office
  let boxShadowHome = bg;
  let boxShadowOffice = bg;
  let borderColourHome = "white";
  let borderColourOffice = "white";
  let bHome = false;
  let bOffice = false;
  let working = "";
  let msg = "You are working...";
  let today = new Date();
  gameState.today = today.toDateString();

  // if distance NaN we are still working out geo location
  if (distance > 0){
    // geo location and distance found      
    if (distance < 100){
      // if within 100m person is in the office
      bOffice = true;
      gameState.noTurns = 5;            
    }
    else {
      // if further away than 1km person is not in the office
      bHome = true;
      gameState.noTurns = 1;      
    }  
  }
  
  // override no turns if they have already played today
  if (gameState.playedDate  ===  gameState.today){
    // already played today come back tomorrow
    gameState.noTurns = 0;
    msg = "You have already played today, see you tomorrow.";
  }    
  
  // check live blocks to see if another player has won today
  var winners:any = useList("winners");
  if (winners === null){
    // dont start the game until we've connected to live blocks
    bHome = false;
    bOffice = false;
  } 
  else {
    let bWinner = checkWinnerToday(winners);
    if(bWinner){
      gameState.noTurns = 0;
      bHome = false;
      bOffice = false;
      msg = "We already have a winner today, try again tomorrow!";
    }
  }

  // bHome = true;           // uncomment for testing
  // gameState.noTurns = 50; //uncomment for testing
  // bOffice = true;      // uncomment for testing

  if (bHome){
    // person at home / not in the office
    boxShadowHome = selectedColour;    
    borderColourHome = "black";
    borderColourOffice = "white";
    working="home";
  }

  if (bOffice){
    // person is in the office
    boxShadowOffice = selectedColour;
    borderColourHome = "white";
    borderColourOffice = "black";
    working="office";
  }
  
  let navigate = useNavigate();
  function playGame(){
    // Navigate to the Game Board Screen  ( <GameBoardScreen /> in AppRouter.tsx )
    navigate(`/Game/${working}` ); // pass working (from home or office) to game board
  } 
 
  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh" bg={bg} color={color} fontSize="20px" fontFamily="Jost" fontWeight="600">
        <Grid p={5} minH="100vh" minW="100vw">    
          <PageHeaderPlay />    
          <GeoLocation />      
          <Box pos="relative" alignContent="center">                
            <SimpleGrid spacing="20px" justifyContent="center" columns={1}>
              <Box width="100%" textAlign="left" fontSize="16px" textColor="black">{msg}</Box>   
              <SimpleGrid height="86px" columns={3} p="10px" width="100%" justifySelf="left" background="white" borderWidth="2px" borderRadius="15px"  boxShadow={`4px 4px 5px 1px ${boxShadowHome}`} borderColor={borderColourHome}>
                <Image p="8px" src={imgHome} />
                <Box alignSelf="center">From Home</Box>    
                <Box cursor="pointer" onClick={() => playGame()}  alignSelf="center" display={(bHome) ? "" : "none"} fontSize="30px" textAlign="right">{'>'}</Box>                
              </SimpleGrid>
              <SimpleGrid height="86px" columns={3} p="10px" width="100%" justifySelf="left"  background="white" borderWidth="2px" borderRadius="15px"  boxShadow={`4px 4px 5px 1px ${boxShadowOffice}`} borderColor={borderColourOffice}>
                <Image p="8px" src={imgOffice} />
                <Box alignSelf="center" p="10px">In The Office</Box>
                <Box cursor="pointer" backgroundColor="white" onClick={() => playGame()}  alignSelf="center" p="10px" display={(bOffice) ? "" : "none"} fontSize="30px" textAlign="right">{'>'}</Box>
              </SimpleGrid>                
            </SimpleGrid>             
          </Box>               
          <PageFooter />
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default PlayScreen;
