import { Link, Box } from "@chakra-ui/react";

/**
 * Footer component
 *
 * @returns the footer to be placed on each page
 */
function PageFooter() {
  return (
    <Box textAlign="center" pos="relative" mt="10px" bottom="0" width="100%">
      <Link
        color="black.500"
        href="https://datacomsocialconnectedness.com/"
        fontSize="1xl"
        target="_blank"
        rel="noopener noreferrer"
      >
        Datacom Social Connectedness
      </Link>
    </Box>
  );
}

export default PageFooter;
