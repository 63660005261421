import { useGeoLocation } from 'use-geo-location';
import { Box } from "@chakra-ui/react";
import { calcGeoDistance, WORK_LATITUDE, WORK_LONGITUDE} from '../utils/utils';

export default function GeoLocation () {
  const { latitude, longitude } = useGeoLocation();
   
  let distance = calcGeoDistance({lat:latitude, lon:longitude}, {lat:WORK_LATITUDE, lon:WORK_LONGITUDE});
  let msg = "Detecting your location please wait..."
  if (!isNaN(distance)){
    // msg = "You are " + Math.round(distance) + " M from the Office";   // uncomment for testing
    msg = ""; 
  }

  return (
    <Box textAlign="center" fontSize="12px">
        <Box>Work Latitude:  {WORK_LATITUDE} Work Longitude: {WORK_LONGITUDE}</Box>      
        <Box>Your Latitude:  {latitude} Your Longitude: {longitude}</Box>
        <Box>{msg}</Box>    
    </Box>
  );
}

