import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomeScreen from "./pages/HomeScreen";
import PlayScreen from "./pages/PlayScreen";
import GameBoardScreen from "./pages/GameBoardScreen";
import TestScreen from "./pages/TestScreen";


/**
 * Configures the app router navigation
 * 
 * @returns the AppRouter page navigation links
 */
function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                {/* Route to Home Screen Page */}
                <Route                    
                    path="/"
                    element={ <HomeScreen /> }
                />
                {/* Route to Play Page */}
                <Route
                    path="/Play"
                    element={ <PlayScreen /> }
                />
                {/* Route to Game Board Page */}
                <Route
                    path="/Game/:working"
                    element={ <GameBoardScreen /> }
                />
                {/* Route to Test Page */}
                <Route
                    path="/Test"
                    element={ <TestScreen /> }
                />               
                {/* Redirect to Play Page by default */}
                <Route
                    path="/redirect"
                    element={ <Navigate to="/Play" /> }
                />
            </Routes>
        </BrowserRouter>
    );
}
export default AppRouter;