// theme.ts

// 1. import `extendTheme` function
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    primary: {
      main: "#ffefc5",
    },
    secondary: {
      main: "#ffb800",
    },
  },
});
// 3. extend the theme
//const theme = extendTheme({ config })

export default theme;
