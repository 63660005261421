export const WORK_LATITUDE = -36.84473;
export const WORK_LONGITUDE = 174.75505;

 /**
 * Takes latitude and longitude coordinates of 2 geo locations and returns the 
 * as the crow flys distance between them
 * 
 * @param cord1 latitude and longitude of location 1
 * @param cord2 latitude and longitude of location 2
 * 
 * @returns the distance between 2 geo locations, in meters
 */
 export function calcGeoDistance(cord1: Coordinate, cord2: Coordinate) 
 {
   let R = 6371; // earths radius in km
   let dLat = toRad(cord2.lat-cord1.lat);
   let dLon = toRad(cord2.lon-cord1.lon);
   let lat1 = toRad(cord1.lat);
   let lat2 = toRad(cord2.lat);

   let a =  Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * 
            Math.cos(lat1) * Math.cos(lat2);
   let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
   let d = (R * c); // kilometers

   d = d * 100;    // meters 

   // return distance in meters
   return d;
 }

 export type Coordinate = {
  lat: number; // latitude coordinate
  lon: number; // longitude coordinate
};

 // Converts numeric degrees to radians
 function toRad(Value: number) 
 {
     return Value * Math.PI / 180;
 } 

export function checkWinnerToday(winners:any){
  // get todays date
  var today:Date = new Date();
  
  // check the winners array from live blocks
  if (winners !== null){   
    // all winners for each day are stored on live blocks
    // the most recent winner is at the end of the list
    if (winners.length > 0){
      let index = winners.length - 1; 
      let lastwinner = winners.get(index);  
      // check live blocks to see if there has been a winner today
      if (lastwinner){
        if (lastwinner.date === today.toDateString()){
          // found a winner today so return true
          return true;
        } 
      }
    }  
  }
  // no winner for today yet so return false
  return false;
}