import { proxy } from "valtio";
import { devtools } from "valtio/utils";

interface State {
  placeHolder: any;
  noTurns: number;
  today: string;
  playedDate: string;
  winnerName: string;
}

const state = proxy<State>({
  placeHolder: null,
  noTurns: 0,
  today: "",
  playedDate: "",
  winnerName: ""
});

devtools(state);

export default state;
