import {
  ChakraProvider,
  Box,
  Grid,
  Image,
  Button,
  theme,
  useColorModeValue
} from "@chakra-ui/react";

import PageFooter from "../components/PageFooter";
import {useNavigate} from "react-router-dom";

// Import the pixel art images used as the game treasure
import imgPanda from "../assets/pixel-art-panda.svg";
import imgSkyTower from "../assets/pixel-art-skytower.svg";
import imgGlobe from "../assets/pixel-art-globe.svg";
import imgPikachu from "../assets/pixel-art-pikachu.svg";
import imgKiwi from "../assets/pixel-art-kiwi.svg";
import imgCat from "../assets/pixel-art-cat.svg";
import imgDog from "../assets/pixel-art-dog.svg";
import imgBall from "../assets/pixel-art-ball.svg";
import imgDonut from "../assets/pixel-art-donut.svg";

/**
 * Home Screen Page
 * 
 * @returns the home screen page
 */
export function HomeScreen() { 
  let navigate = useNavigate();

  const play = () => {
    // Navigate to the Play Screen  ( <PlayScreen /> in AppRouter.tsx )
   navigate('/Play');
  } 
  // set the colour scheme
  const bg = useColorModeValue('#ffe248', '#ffb800');
  const color = useColorModeValue('black', 'white');
   
  return (
    
    <ChakraProvider theme={theme} >         
      <Box maxH="100vh" bg={bg} color={color} fontFamily="Jost" fontWeight="600">
        <Grid minH="100vh" p={5} justifyItems="center" columnGap="10px">              
          <Image p="5px" src='pickcel-black.png' height="58px" mt="80px"></Image>  
          <Grid gridTemplateColumns="1fr 1fr 1fr" alignItems="center">              
          <Image p="5px" height="50px" src={imgCat}></Image>
          <Image p="5px" height="50px" src={imgSkyTower}></Image>
          <Image p="5px" height="50px" src={imgPanda}></Image>
          <Image p="5px" height="50px" src={imgGlobe}></Image>
          <Image p="5px" height="50px" src={imgPikachu}></Image>          
          <Image p="5px" height="50px" src={imgDog}></Image>  
          <Image p="5px" height="50px" src={imgDonut}></Image>   
          <Image p="5px" height="50px" src={imgBall}></Image> 
          <Image p="5px" height="50px" src={imgKiwi}></Image>         
          </Grid>
          
          <Button
            width="150px"
            alignSelf="center"
            border="2px"
            borderColor="black"
            borderRadius="9px"
            boxShadow="4px 4px 5px 1px #e39234"   
            fontWeight="semibold"      
            onClick={play}             
          >
          PLAY
          </Button> 
          <PageFooter />
        </Grid>
      </Box>
     
    </ChakraProvider>
  );
}

export default HomeScreen;
