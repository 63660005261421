import {
  Box,
  Image,
  Link,
} from "@chakra-ui/react";
import WhoIsHere from "./WhoIsHere";

/**
 * Page Header Play Screen
 * 
 * @returns the header for the Play page
 */
function PageHeaderPlay() {
 
  return (
    <Box justifySelf="center">   
      <Link href="/">       
        <Image src='pickcel-black.png' height="45px" mt="20px"></Image> 
      </Link>
      <WhoIsHere />             
    </Box>
      
  );
}

export default PageHeaderPlay;
