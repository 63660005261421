import { useEffect, useState } from "react";
import { 
  Box, 
  Grid, 
  SimpleGrid, 
  Image
} from "@chakra-ui/react";
import { PickcelProp, COLUMNS, getCells, IGrid } from "../utils/GameBoardUtils";
import imgHeart from "../assets/heart.svg"; // winner cell
import imgPikachu from "../assets/pixel-art-pikachu.svg"; // winner pixel art
import imgDog from "../assets/pixel-art-dog.svg"; // winner pixel art
import imgCat from "../assets/pixel-art-cat.svg"; // winner pixel art
import imgGlobe from "../assets/pixel-art-globe.svg"; // winner pixel art
import imgBall from "../assets/pixel-art-ball.svg"; // winner pixel art
import imgDonut from "../assets/pixel-art-donut.svg"; // winner pixel art
import imgKiwi from "../assets/pixel-art-kiwi.svg"; // winner pixel art
import imgPanda from "../assets/pixel-art-panda.svg"; // winner pixel art
import imgSkyTower from "../assets/pixel-art-skytower.svg"; // winner pixel art
import { useSnapshot } from "valtio";
import gameState from "../utils/state";
import GameWinner from "./GameWinner";
import { checkWinnerToday } from '../utils/utils';
import { useList} from "../liveblocks.config";


export default function GameBoard({
  columns,
  cell_type_treasure,
  cell_type_close,
  cell_size,
}: PickcelProp) {
  const { noTurns } = useSnapshot(gameState);
  const [turns, setTurns] = useState<number>(noTurns);
  const [grid, setGrid] = useState<IGrid>({});
  const [gameover, setGameOver] = useState<boolean>(false);
  const [gamewon, setGameWon] = useState<boolean>(false);
  const [showwinner, setShowWinner] = useState<boolean>(false);
  
  useEffect(() => {
    
    function initialize() {
      let cells = getCells(COLUMNS);

      // Place the TREASURE, randomly
      //
      let tX = Math.floor(Math.random() * COLUMNS);
      let tY = Math.floor(Math.random() * COLUMNS);
      cells[tX][tY] = 1; // The TREASURE

      // Place the CLOSE cells around the TREASURE
      //
      // NOTE:
      // cLeft is CLOSE STARTING POINT LEFT
      // cL, cR, cT and cB are CLOSE CELL LEFT, RIGHT, TOP and BOTTOM
      //
      let idx,
        cL,
        cR,
        cT,
        cB = 0;
      let cLeft = tX - 1;
      let cTop = tY - 1;

      for (idx = 0; idx < 3; idx++) {
        cL = cLeft + idx;
        cT = cTop;
        cB = cTop + 2;

        if (cL >= 0 && cL < COLUMNS) {
          if (cT >= 0 && cT < COLUMNS) cells[cL][cT] = 2;
          if (cB >= 0 && cB < COLUMNS) cells[cL][cB] = 2;
        }
      }
      cR = cLeft + 2;
      cT = cTop + 1;
      if (cT >= 0) {
        if (cLeft >= 0) cells[cLeft][cT] = 2;
        if (cR < COLUMNS) cells[cR][cT] = 2;
      }

      setGrid(cells);
    }
    initialize();
  }, []);

  const handleCell = (column: number, rowIndex: number, cell: number) => {
    // if the games over disable the cell click
    if (another_player_won || gameover || (turns === 0)) {
      setGameOver(true);
      return false;
    }
    let today = new Date();
    const currentCell = -1 * cell; // Flip the sign on the value to signify the cell has been selected.
    let chosenCell: any = { ...grid };
    chosenCell[column][rowIndex] = currentCell;

    if (currentCell === -1) {
      // user selected the treasure
      setTurns(0);
      setGameOver(true);
      setGameWon(true);
      gameState.playedDate = today.toDateString();
      showWinner();
    } else {
      // user didn't select the treasure
      if (turns <= 1) {
        // user has no turns left
        setTurns(0);
        setGameOver(true);
        setGameWon(false);
        gameState.playedDate = today.toDateString();
      } else {
        setTurns(turns - 1);
        setGrid(chosenCell);
      }
    }
  };

  function ShowCellColor(cell: number) {
    // cell = -cell; // NOTE: DEBUG CODE, uncomment to show actual cell colors
    if (cell > 0) {
      if (cell === 1){
        return "#faf9f6"; // so I can cheat for demo :)
      }
      return "white";
    } else {
      const absCell = Math.abs(cell);
      return absCell === cell_type_treasure
        ? "white"
        : absCell === cell_type_close
        ? "#FFB800"
        : "#EC0505";
    }
  };

  function showWinner(){
  /**
   * wait a moment 
   * let the winner see the pixel art come to life on the game board
   * before showing the form to enter their name
   */
    let counter = 5;
    let intervalId = setInterval(() => {
      counter = counter - 1;
      if(counter === 0) {
        setShowWinner(true);
        clearInterval(intervalId);
      }
    }, 700)
  }

  //randomly select which of the 9 pixel art images to show the winner
  var imgWinner:string;
  let min = 1;
  let max = 9;
  const rndInt = Math.floor(Math.random() * (max - min + 1)) + min;
  switch(rndInt){
    case 1:{
      imgWinner = imgBall;
      break;
    } 
    case 2:{
      imgWinner = imgCat;
      break;
    } 
    case 3:{
      imgWinner = imgDog;
      break;
    } 
    case 4:{
      imgWinner = imgDonut;
      break;
    }
    case 5:{
      imgWinner = imgGlobe;
      break;
    } 
    case 6:{
      imgWinner = imgKiwi;
      break;
    } 
    case 7:{
      imgWinner = imgPanda;
      break;
    } 
    case 8:{
      imgWinner = imgPikachu;
      break;
    }
    default:{
      imgWinner = imgSkyTower;
      break;
    }
  }

  var winners:any = useList("winners"); // list of previous winners, from live blocks
  var another_player_won:boolean = checkWinnerToday(winners);
  
  return (
    <div>
      <Box width="100%" textAlign="center">
        Turns Left: {another_player_won ? "0" : turns}
      </Box>
      <Grid position="absolute" width="100%" justifyContent="center">
        <Image
          opacity={showwinner ? "0" : "1"}
          display={gamewon ? "" : "none"}
          src={imgWinner}
          p="20px"
          width="300px"
          height="300px"
        ></Image>
      </Grid>
      <SimpleGrid
        justifyContent="center"
        data-testid="grid-wrapper"
        gridTemplateColumns={`repeat(${columns}, ${cell_size}px)`}
        gridGap="2px"
        mb="4rem"
      >
        {grid &&
          Object.keys(grid).map((column, index) => (
            <SimpleGrid
              key={index}
              className="column"
              data-testid="column"
              gridGap="2px"
            >
              {grid[column].map((cell: number, rowIndex: number) => (
                <Box
                  textAlign="center"
                  onClick={() => handleCell(+column, rowIndex, cell)}
                  key={rowIndex}
                  data-testid="cell"
                  width={`${cell_size}px`}
                  height={`${cell_size}px`}
                  borderRadius="5px"
                  backgroundColor={ShowCellColor(cell)}
                  backgroundImage={
                    Math.abs(cell) === cell_type_treasure && cell < 0
                      ? imgHeart
                      : ""
                  }
                  cursor="pointer"
                ></Box>
              ))}
            </SimpleGrid>
          ))}
      </SimpleGrid>
      <Box display={showwinner ? "" : "none"} width="100%" textAlign="center">
        <GameWinner />
      </Box>     
      <Box
        display={(gameover || another_player_won) ? "" : "none"}
        width="100%"
        textAlign="center" >
        GAME OVER
      </Box>
      <Box display={(gameover || another_player_won) ? "" : "none"} width="100%" textAlign="center">
        We have found our winner for today
      </Box>
      <Box display={(gameover || another_player_won) ? "" : "none"} width="100%" textAlign="center">
        see you tommorrow!
      </Box>              
    </div>
  );
}
